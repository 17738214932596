import React, {useState} from 'react';
import {createRoot} from 'react-dom/client';
import JshModule from '@core/scripts/helper/jsh-module';
import NavigationSmall from '../../components/navigation/NavigationSmall.jsx';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {useBreakpoint} from "../../components/hooks/use-breakpoint.js";
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import {persistQueryClient} from "@tanstack/react-query-persist-client";

const {moduleScope, config} = JshModule('navigation-small-new');

const {currentPage, currentShopId} = config;
const queryClient = new QueryClient();

const sessionStoragePersister = createSyncStoragePersister({
    storage: window.sessionStorage,
})
persistQueryClient({
    queryClient,
    persister: sessionStoragePersister,
});

const NavigationWrapper = () => {
    const breakpoint = useBreakpoint();

    if(!['small', 'medium'].includes(breakpoint)) {
        return <></>;
    }
    return <NavigationSmall  currentShopId={currentShopId} currentPage={currentPage}/>;
};

createRoot(moduleScope).render(
    <QueryClientProvider client={queryClient}>
        <NavigationWrapper />
    </QueryClientProvider>,
);